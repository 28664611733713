import React, { Fragment, useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import SEO from 'shared/SEO'
import Layout from 'layout/Layout'
import styled from 'styled-components'
import Content from 'components/shared/Content'

const ContentContainer = styled.div`
  min-height: 100vh;
  background-color: ${(props) => props.theme.color.face.main};
  margin-bottom: -3rem;
  padding-top: 200px;

  & h1 {
    font-size: 3rem;
    font-weight: 600;
    color: white;
    text-align: center;
  }
`

const PayStatusContainer = styled.div`
  background-color: white;
  max-width: 768px;
  margin: 40px auto 0;
  padding: 40px;
`

const ThankYouContainer = styled.div`
  background-color: white;
  max-width: 768px;
  margin: 20px auto 0;
  padding: 40px;
`

const PageTemplate = ({
  data: {
    page: { title, seo, paginaBedankt },
  },
  pageContext,
}) => {
  const [data, setData] = useState(null)
  const [paymentUrl, setPaymentUrl] = useState(null)

  useEffect(() => {
    if (typeof window === 'undefined') return

    const paymentId = window.localStorage.getItem('studiumPaymentId')
    const paymentUrl = window.localStorage.getItem('studiumPaymentUrl')

    const fetchData = async () => {
      const response = await fetch(
        `https://admin.studium.nl/wp-json/afasstudium/v1/checkPayment?id=${paymentId}`
      )
      const data = await response.json()
      data[0].data = JSON.parse(data[0].data)
      setData(data[0])
    }

    setPaymentUrl(paymentUrl)
    fetchData()
  }, [])

  useEffect(() => {
    if (data) {
      console.log(data)
    }
  }, [data])

  const statusLiteral = {
    pending: 'In afwachting',
    paid: 'Betaald',
    failed: 'Mislukt',
    canceled: 'Geannuleerd',
    expired: 'Verlopen',
    authorized: 'Geauthoriseerd',
  }

  return (
    <Layout>
      <SEO seo={seo} />

      <ContentContainer>
        <div className="container">
          {data && (
            <Fragment>
              <h1>{data.status != 'paid' ? '' : paginaBedankt.title}</h1>
              <PayStatusContainer>
                <h2 className="font-weight-xl font-size-l color-secondary">
                  {data?.data?.CourseData?.title}
                </h2>
                <h3
                  className={`font-weight-xl font-size-m ${
                    data.status == 'paid' ? 'color-third' : 'color-error'
                  }`}
                >
                  Betaling status
                </h3>
                <div className="d-flex">
                  <p>{statusLiteral[data.status]}</p>
                  {paymentUrl && data.status != 'paid' && (
                    <a
                      className="ps-3 link-underline color-third"
                      href={paymentUrl}
                      target="_blank"
                    >
                      Check hier je betaling
                    </a>
                  )}
                </div>

                <div>
                  {Number(data.amount).toLocaleString('nl-NL', {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </div>
                <hr />
                <div className="">
                  <div className="font-weight-l color-secondary">
                    Eerste lesdatum
                  </div>
                  <div>{data?.data?.CourseData?.startdatum}</div>
                </div>
                <div className="pt-3">
                  <div className="font-weight-l color-secondary">Tijdsduur</div>
                  <div>{data?.data?.CourseData?.education?.duration}</div>
                </div>
              </PayStatusContainer>
            </Fragment>
          )}
        </div>
      </ContentContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wpPage(databaseId: { eq: $wordpress_id }) {
      ...ThanksPageFrag
    }
  }
`

export default PageTemplate
